import {Modal, notification} from 'antd';
import {CheckCircle, XCircle, WarningCircle} from 'phosphor-react';
import './index.less';

const {error} = Modal;

let shown = false;

notification.config({
	maxCount: 1
});

function getIcon(type: 'success' | 'error' | 'warning') {
	switch (type) {
		case 'success':
			return <CheckCircle color="#6C5DD3" size={30} weight="fill" />;
		case 'warning':
			return <WarningCircle color="#e39024" size={30} weight="fill" />;
		case 'error':
			return <XCircle color="#CE1126" size={30} weight="fill" />;
		default:
			return <CheckCircle color="#CE1126" size={30} weight="fill" />;
	}
}

const toast = ({text, type}: {text: string; type: 'success' | 'error' | 'warning'}) => {
	notification.info({
		message: text,
		// description: text,
		placement: 'top' as any,
		icon: getIcon(type)
	});
};

const errorModal = ({text}: {text: string}) => {
	const modal = error({
		title: text,
		className: 'modalPre',
		okButtonProps: {size: 'large', className: 'main-btn'},
		onCancel: () => (shown = false),
		onOk: () => (shown = false),
		centered: true,
		okType: 'ghost',
		closable: false,
		maskClosable: true,
		width: 600
	});
};

export default {
	success: (text: string) => toast({type: 'success', text}),
	warning: (text: string) => toast({type: 'warning', text}),
	error: (text: string) => {
		errorModal({text});
		shown = true;
	}
};
