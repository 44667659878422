enum MAIN {
	ATTENDANCE_BY_WEEK_ID = '/api/attendance/main/'
}

enum PRESENCE {
	PRESENCE = '/api/attendance/presence',
	PRESENCE_BY_ID = '/api/attendance/presence/'
}

enum ABSENCE {
	ABSENCE = '/api/attendance/absence'
}

enum COMPLETED_CLASSES {
	MAIN = '/api/attendance/completedClasses'
}

export default {
	MAIN,
	PRESENCE,
	ABSENCE,
	COMPLETED_CLASSES
};
