import axiosClient from 'utils/axiosClient';
import api from 'api';

const getAttendanceByWeekId = async (lessonWeekID: number, lessonsID: number, params: any) => {
	const response = await axiosClient.get(
		api.ATTENDANCE.MAIN.ATTENDANCE_BY_WEEK_ID + lessonWeekID + '/' + lessonsID,
		{params}
	);
	return response.data;
};

const updateAttendance = async (lessonWeekID: number, lessonsID: number, attendanceID: number, data: any) => {
	const response = await axiosClient.patch(
		api.ATTENDANCE.MAIN.ATTENDANCE_BY_WEEK_ID + lessonWeekID + '/' + lessonsID + '/' + attendanceID,
		data
	);
	return response.data;
};

const service = {
	getAttendanceByWeekId,
	updateAttendance
};

export default service;
