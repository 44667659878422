import axios from 'axios';
import api from 'api';
import i18next from 'i18next';
import Toast from 'components/Toast';

const exportExcel = (
	filters: any,
	ids: number[],
	api: string,
	fileName: string,
	type: 'xlsx' | 'pdf' = 'xlsx'
) => {
	axios
		.get(process.env.REACT_APP_API_URL + api, {
			responseType: 'blob',
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('token'),
				'Accept-Language': i18next.language
			},
			params: {...filters, IDs: ids.length ? ids.join() : undefined}
		})
		.then(response => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `${fileName}.${type}`);
			document.body.appendChild(link);
			link.click();
		})
		.catch(err => {
			err.response.data.text().then((res: string) => Toast.error(JSON.parse(res).message));
		});
};
const exportExcelWithPromis = (
	filters: any,
	ids: number[],
	api: string,
	fileName: string,
	type: 'xlsx' | 'pdf' = 'xlsx'
) => {
	return axios
		.get(process.env.REACT_APP_API_URL + api, {
			responseType: 'blob',
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('token'),
				'Accept-Language': i18next.language
			},
			params: {...filters, IDs: ids.length ? ids.join() : undefined}
		})
		.then(response => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `${fileName}.${type}`);
			document.body.appendChild(link);
			link.click();
		})
		.catch(err => {
			err.response.data.text().then((res: string) => Toast.error(JSON.parse(res).message));
		});
};

const exportCandidatesExcel = (filters: any, ids: number[]) => {
	exportExcel(filters, ids, api.EXCEL.CANDIDATES, 'Candidates');
};

const exportCandidateReference = (id: number) => {
	exportExcel(
		{},
		[],
		api.ADMISSION.CANDIDATES.CANDIDATE_BY_ID + id + '/reference',
		'Candiate Reference',
		'pdf'
	);
};

const exportExamMarkStatementPdf = (examID: number) => {
	exportExcel(
		{},
		[],
		api.EXAMS.EXAMS.EXAMS_BY_ID + examID + '/export/examMarkStatementPdf',
		'Exam Mark Statement',
		'pdf'
	);
};
const exportExamMarkEmptyStatementPdf = (examID: number) => {
	exportExcel(
		{},
		[],
		api.EXAMS.EXAMS.EXAMS_BY_ID + examID + '/export/examMarkStatementEmptyPdf',
		'Exam Mark Statement',
		'pdf'
	);
};

const exportStudentsCardsPdf = (ids: number[]) => {
	exportExcel({}, ids, api.STUDENTS.EXPORT_STUDENTS_CARDS_PDF, 'Students Cards', 'pdf');
};

const exportLessonModelPdf = (facultyID: number) => {
	exportExcel(
		{facultyID},
		[],
		api.CURRICULUM.CURRICULUM.EXPORT_LESSON_MODEL_PDF,
		'Educational model',
		'pdf'
	);
};

const exportPaymentsExcel = (filters: any, ids: number[]) => {
	exportExcel(filters, ids, api.EXCEL.PAYMENTS, 'Payments');
};

const exportAdmissionExamsExcel = (filters: any, ids: number[]) => {
	exportExcel(filters, ids, api.EXCEL.ADMISSION_EXAMS, 'Exams');
};

const exportAdmissionFacultiesExcel = (filters: any, ids: number[]) => {
	exportExcel(filters, ids, api.EXCEL.ADIMSSION_FACULTIES, 'Faculties');
};

const exportDistributionExcel = (filters: any, ids: number[]) => {
	exportExcel(filters, ids, api.EXCEL.DISTRIBUTION, 'Exams Distribution');
};

const exportMarksExcel = (filters: any, ids: number[]) => {
	exportExcel(filters, ids, api.EXCEL.ADMISSION_MARKS, 'Marks');
};

const exportAppealsExcel = (filters: any, ids: number[]) => {
	exportExcel(filters, ids, api.EXCEL.APPEALS, 'Appeals');
};

const exportAppealsDistributionExcel = (filters: any, ids: number[]) => {
	exportExcel(filters, ids, api.EXCEL.APPEALS_DISTRIBUTION, 'Appeals Distribution');
};

const exportUsersExcel = (filters: any, ids: number[]) => {
	exportExcel(filters, ids, api.EXCEL.USERS, 'Users');
};

const exportSummaryExcel = (filters: any, ids: number[]) => {
	exportExcel(filters, ids, api.EXCEL.SUMMARY, 'Summary');
};

const exportLecturersExcel = (filters: any, ids: number[]) => {
	exportExcel(filters, ids, api.EXCEL.LECTURERS, 'Lecturers');
};

const exportLecturersSalariesExcel = (filters: any, ids: number[]) => {
	exportExcel(filters, ids, api.EXCEL.LECTURERS_SALARIES, 'Lecturers Salaries');
};

const exportStudentsExcel = (filters: any, ids: number[]) => {
	exportExcel(filters, ids, api.EXCEL.STUDENTS, 'Students');
};

const exportStudentApplication = (filters: AnalyserNode, ids: number[]) => {
	exportExcel(filters, ids, api.EXCEL.STUDENT_APPLICATION, 'Student Application');
};

const exportStudentsPaymentsExcel = (filters: any, ids: number[]) => {
	exportExcel(filters, ids, api.EXCEL.STUDENT_PAYMENTS, 'Students Payments');
};

const exportStudentsDiscountsExcel = (filters: any, ids: number[]) => {
	exportExcel(filters, ids, api.EXCEL.STUDENT_DISCOUNTS, 'Students Discounts');
};

const exportPresenceExcel = (filters: any, ids: number[]) => {
	exportExcel(filters, ids, api.EXCEL.ATTENDANCE_PRESENCE, 'Students Presence');
};

const exportAbsenceExcel = (filters: any, ids: number[]) => {
	exportExcel(filters, ids, api.EXCEL.ATTENDANCE_ABSENCE, 'Students Absence');
};

const exportCompletedClassesExcel = (filters: any, ids: number[]) => {
	exportExcel(filters, ids, api.EXCEL.ATTENDANCE_CLASSES, 'Completed Classes');
};

const exportStudentsSurveyExcel = (filters: any, ids: number[]) => {
	exportExcel(filters, ids, api.EXCEL.STUDENT_SURVEY, 'Students Survey');
};

const exportCurriculumFacultiesExcel = (filters: any, ids: number[]) => {
	exportExcel(filters, ids, api.EXCEL.CURRICULUM_FACULTIES, 'Curriculum Faculties');
};

const exportCurriculumFlowsExcel = (filters: any, ids: number[]) => {
	exportExcel(filters, ids, api.EXCEL.CURRICULUM_FLOWS, 'Curriculum Flows');
};

const exportCurriculumGroupsExcel = (filters: any, ids: number[]) => {
	exportExcel(filters, ids, api.EXCEL.CURRICULUM_GROUPS, 'Curriculum Groups');
};

const exportCurriculumLessonsWeeksExcel = (filters: any, ids: number[]) => {
	exportExcel(filters, ids, api.EXCEL.CURRICULUM_LESSONS_WEEKS, 'Lessons Weeks');
};

const exportCurriculumLessonsExcel = (weekID: number | string, ids: number[]) => {
	exportExcel({}, ids, api.EXCEL.CURRICULUM_LESSONS + weekID + '/export/xlsx', 'Lessons data');
};

const exportAttendanceExcel = (weekID: number, lessonID: number, filters: any, ids: number[]) => {
	exportExcel(
		filters,
		ids,
		api.EXCEL.ATTENDANCE_MAIN + weekID + '/' + lessonID + '/export/xlsx',
		'Attendance'
	);
};

const exportAttendanceMainExcel = (weekID: number, filters: any, ids: number[]) => {
	exportExcel(filters, ids, api.EXCEL.CURRICULUM_LESSONS + weekID + '/export/xlsx', 'Attendance');
};

const exportCurriculumExcel = (filters: any, ids: number[]) => {
	exportExcel(filters, ids, api.EXCEL.CURRICULUM, 'Curriculum');
};

const exportExamsArchivedMarks = (filters: any, ids: number[]) => {
	exportExcel(filters, ids, api.EXCEL.EXAMS_ARCHIVED_MARKS, 'Archived Marks');
};

const exportExamsArchivedMarksPdf = (ids: number[]) => {
	exportExcel({}, ids, api.EXCEL.EXAMS_ARCHIVED_MARKS_PDF, 'Archived Marks', 'pdf');
};

const exportExamsExcel = (filters: any, ids: number[]) => {
	exportExcel(filters, ids, api.EXCEL.EXAMS, 'Exams');
};

const exportExamsMarksExcel = (filters: any, ids: number[]) => {
	exportExcel(filters, ids, api.EXCEL.MARKS, 'Marks');
};

const exportExamsConsultationExcel = (filters: any, ids: number[]) => {
	exportExcel(filters, ids, api.EXCEL.EXAMS_CONSULTATION, 'Exams Consultation');
};

const exportExamsDistributionExcel = (filters: any, ids: number[]) => {
	exportExcel(filters, ids, api.EXCEL.EXAMS_DISTRIBUTION, 'Exams Distribution');
};

const exportExamsSummaryExcel = (filters: any, ids: number[]) => {
	exportExcel(filters, ids, api.EXCEL.EXAMS_SUMMARY, 'Exams Summary');
};

const exportExamsSummaryAcademicYearExcel = (filters: any, ids: number[]) => {
	exportExcel(filters, ids, api.EXCEL.EXAMS_SUMMARY_ACADEMIC_YEAR, 'Exams Annual Summary');
};

const exportExamsControllersExcel = (examID: number, filters: any, ids: number[]) => {
	exportExcel(
		filters,
		ids,
		api.EXCEL.EXAMS_CONTROLLERS + examID + '/controllerList/export/xlsx',
		'Exams Controllers'
	);
};

const exportScheduleExcel = (filters: any) => {
	exportExcel(filters, [], api.EXCEL.SCHEDULE, 'Schedule');
};

const exportCommandsExcel = (filters: any, ids: number[]) => {
	exportExcel(filters, ids, api.EXCEL.COMMANDS, 'Commands');
};
const exportAcademicNewsletter = (id: number) => {
	const api = `/api/students/main/${id}/export/thirteenFormPdf`;
	return exportExcelWithPromis({}, [], api, 'Academic bulletin', 'pdf');
};
const exportReferencePdf = (id: number) => {
	const api = `/api/students/main/${id}/export/referencePdf`;
	return exportExcelWithPromis({}, [], api, 'Credit reference', 'pdf');
};
const exportStudyReferencePdf = (id: number, isForeigner: boolean) => {
	const api = `/api/students/main/${id}/export/${
		isForeigner ? 'studyReferenceForForeignersPdf' : 'studyReferencePdf'
	}`;
	return exportExcelWithPromis({}, [], api, 'Reference about studying at university', 'pdf');
};
const exportTemporaryStudyReferencePdf = (id: number) => {
	const api = `/api/students/main/${id}/export/temporaryStudyReferencePdf`;
	return exportExcelWithPromis({}, [], api, 'Reference for Temporary participation in courses', 'pdf');
};
const exportAttachmentReferencePdf = (id: number) => {
	const api = `/api/students/main/${id}/export/attachmentReferencePdf`;
	return exportExcelWithPromis({}, [], api, 'Attachment reference', 'pdf');
};
const exportAcademicReferencePdf = (id: number) => {
	const api = `/api/students/main/${id}/export/blueSheetReferencePdf`;
	return exportExcelWithPromis({}, [], api, 'Academic reference', 'pdf');
};
const exportTrustDeedPdf = (id: number) => {
	const api = `/api/students/main/${id}/export/trustDeedPdf`;
	return exportExcelWithPromis({}, [], api, 'Power of attorney', 'pdf');
};

const exportDiplomaPdf = (id: number) => {
	const api = `/api/students/main/${id}/export/diplomaSupplementPdf`;
	return exportExcelWithPromis({}, [], api, 'Diploma', 'pdf');
};

const exportAcademicProgressReferencePdf = (id: number) => {
	const api = `/api/students/main/${id}/export/academicProgressReferencePdf`;
	return exportExcelWithPromis({}, [], api, 'Academic progress reference', 'pdf');
};

const exportReferenceForOpeningACardAccountPdf = (id: number) => {
	const api = `/api/students/main/${id}/export/cardAccountOpeningReferencePdf`;
	return exportExcelWithPromis({}, [], api, 'Reference for opening a card account', 'pdf');
};

const service = {
	exportCandidatesExcel,
	exportTrustDeedPdf,
	exportAcademicReferencePdf,
	exportCandidateReference,
	exportPaymentsExcel,
	exportAdmissionExamsExcel,
	exportAdmissionFacultiesExcel,
	exportDistributionExcel,
	exportMarksExcel,
	exportAppealsExcel,
	exportAppealsDistributionExcel,
	exportUsersExcel,
	exportSummaryExcel,
	exportLecturersExcel,
	exportLecturersSalariesExcel,
	exportStudentsExcel,
	exportStudentsPaymentsExcel,
	exportStudentsDiscountsExcel,
	exportPresenceExcel,
	exportAbsenceExcel,
	exportCompletedClassesExcel,
	exportStudentsSurveyExcel,
	exportStudentApplication,
	exportStudentsCardsPdf,
	exportLessonModelPdf,
	exportCurriculumFacultiesExcel,
	exportCurriculumFlowsExcel,
	exportCurriculumGroupsExcel,
	exportCurriculumLessonsWeeksExcel,
	exportCurriculumLessonsExcel,
	exportAttendanceExcel,
	exportAttendanceMainExcel,
	exportCurriculumExcel,
	exportExamsArchivedMarks,
	exportExamsArchivedMarksPdf,
	exportExamsExcel,
	exportExamsMarksExcel,
	exportExamsConsultationExcel,
	exportExamsDistributionExcel,
	exportExamsSummaryExcel,
	exportExamsControllersExcel,
	exportCommandsExcel,
	exportExamsSummaryAcademicYearExcel,
	exportScheduleExcel,
	exportAcademicNewsletter,
	exportReferencePdf,
	exportStudyReferencePdf,
	exportTemporaryStudyReferencePdf,
	exportAcademicProgressReferencePdf,
	exportAttachmentReferencePdf,
	exportExamMarkStatementPdf,
	exportExamMarkEmptyStatementPdf,
	exportDiplomaPdf,
	exportReferenceForOpeningACardAccountPdf
};

export default service;
