enum MARKS {
	MARKS = '/api/archive/marks',
	MARKS_BY_ID = '/api/archive/marks/',
	UPLOAD_MARKS = '/api/archive/marks/uploadStudentMarks'
}

enum COMMANDS {
	COMMANDS = '/api/archive/commands',
	UPLOAD_COMMANDS = '/api/archive/commands/uploadCommands'
}

export default {
	MARKS,
	COMMANDS
};
